import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ContentParagraph, Paragraph } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "paragraph"
    }}>{`Paragraph`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Paragraph } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2681%3A5'} storybook={'/?path=/story/content-content--playground'} name={'Paragraph'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Paragraph – компонент для абзацев текста.
Стили текста соответствуют токенам `}<inlineCode parentName="p">{`text_paragraph_large`}</inlineCode>{`, либо `}<inlineCode parentName="p">{`text_paragraph_small`}</inlineCode>{`.`}</p>
    <p>{`При использовании контентной версии компонента (`}<inlineCode parentName="p">{`ContentParagraph`}</inlineCode>{`) добавляются внешние вертикальные отступы.
При использовании компонента отдельно (`}<inlineCode parentName="p">{`Paragraph`}</inlineCode>{`) внешние отступы по умолчанию отключены. Управлять ими можно с помощью пропа `}<inlineCode parentName="p">{`hasMargin`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'<>\n  <Paragraph hasMargin>\n    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A animi\n    consectetur debitis delectus dicta dignissimos distinctio dolores eos ex\n    harum laborum libero magnam maxime modi molestias officia pariatur qui\n    recusandae reiciendis repellat, repudiandae sequi similique sint sit\n    totam! Accusamus aliquid fuga porro rerum, veritatis voluptas voluptates.\n    At atque consequatur cum dicta dolore doloribus eaque error ipsam,\n    molestias necessitatibus omnis perferendis quas quasi qui ratione\n    recusandae reprehenderit sint suscipit veniam voluptate.\n  </Paragraph>\n  <Paragraph hasMargin>\n    Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid\n    consectetur consequatur deserunt doloremque ea enim error fuga impedit,\n    inventore magni maiores modi necessitatibus numquam placeat provident quae\n    quaerat quibusdam quis ratione recusandae repudiandae sed ut veniam\n    veritatis vitae voluptate voluptatum! Aut eius et illo inventore minus\n    molestias officia quasi sapiente unde? Aliquam assumenda blanditiis\n    consectetur cupiditate delectus error facere fuga laudantium molestias\n    placeat quas, quis quos sed suscipit voluptas.\n  </Paragraph>\n</>'} __scope={{
      props,
      DefaultLayout,
      ContentParagraph,
      Paragraph,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <Paragraph hasMargin mdxType="Paragraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A animi consectetur debitis delectus dicta dignissimos distinctio dolores eos ex harum laborum libero magnam maxime modi molestias officia pariatur qui recusandae reiciendis repellat, repudiandae sequi similique sint sit totam! Accusamus aliquid fuga porro rerum, veritatis voluptas voluptates. At atque consequatur cum dicta dolore doloribus eaque error ipsam, molestias necessitatibus omnis perferendis quas quasi qui ratione recusandae reprehenderit sint suscipit veniam voluptate.
    </Paragraph>
    <Paragraph hasMargin mdxType="Paragraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid consectetur consequatur deserunt doloremque ea enim error fuga impedit, inventore magni maiores modi necessitatibus numquam placeat provident quae quaerat quibusdam quis ratione recusandae repudiandae sed ut veniam veritatis vitae voluptate voluptatum! Aut eius et illo inventore minus molestias officia quasi sapiente unde? Aliquam assumenda blanditiis consectetur cupiditate delectus error facere fuga laudantium molestias placeat quas, quis quos sed suscipit voluptas.
    </Paragraph>
  </>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "использование-на-контентных-страницах"
    }}>{`Использование на контентных страницах`}</h3>
    <p>{`При использовании контентной версии компонента (`}<inlineCode parentName="p">{`ContentParagraph`}</inlineCode>{`) добавляются внешние вертикальные отступы.`}</p>
    <Playground __position={2} __code={'<>\n  <ContentParagraph>\n    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus\n    aliquid animi delectus deserunt dicta dignissimos dolorum eos eum,\n    incidunt minima nam necessitatibus nihil nobis officiis perferendis,\n    provident quae quia quo ratione reiciendis repellendus rerum ullam, ut\n    veniam voluptates voluptatibus. Aspernatur commodi consequatur doloremque,\n    facilis iste magnam maiores nihil possimus recusandae repellat, sed ullam.\n    Architecto consectetur dolores nihil, odit perspiciatis quod saepe?\n    Blanditiis culpa dolorem earum nulla quibusdam reiciendis vel!\n  </ContentParagraph>\n  <ContentParagraph>\n    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum\n    iure officia placeat quidem, repudiandae vitae voluptatibus. Ad aperiam\n    architecto eius et, eum ex facilis, labore libero magnam minus modi nisi\n    obcaecati possimus, quasi reprehenderit. Enim explicabo molestias numquam\n    suscipit voluptate? Aliquid aperiam delectus laborum necessitatibus nihil\n    nisi nobis perferendis quos ratione soluta! Blanditiis corporis dolorum\n    illo, iure minima obcaecati qui rem saepe sed voluptatibus! At debitis\n    tempore voluptate voluptatum!\n  </ContentParagraph>\n  <ContentParagraph>\n    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,\n    assumenda aut dignissimos doloribus ex ipsa iste itaque iure quibusdam\n    quisquam quod repellendus sint suscipit tempore voluptas? Accusamus alias\n    aliquam architecto corporis delectus eos explicabo fuga illum in ipsam\n    itaque molestiae natus nobis nostrum pariatur, perferendis perspiciatis\n    praesentium, quod repudiandae rerum suscipit ut voluptas. Atque cum hic\n    itaque laudantium necessitatibus nobis possimus quibusdam? At consectetur\n    nisi praesentium quis quod recusandae sint?\n  </ContentParagraph>\n</>'} __scope={{
      props,
      DefaultLayout,
      ContentParagraph,
      Paragraph,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <ContentParagraph mdxType="ContentParagraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus aliquid animi delectus deserunt dicta dignissimos dolorum eos eum, incidunt minima nam necessitatibus nihil nobis officiis perferendis, provident quae quia quo ratione reiciendis repellendus rerum ullam, ut veniam voluptates voluptatibus. Aspernatur commodi consequatur doloremque, facilis iste magnam maiores nihil possimus recusandae repellat, sed ullam. Architecto consectetur dolores nihil, odit perspiciatis quod saepe? Blanditiis culpa dolorem earum nulla quibusdam reiciendis vel!
    </ContentParagraph>
    <ContentParagraph mdxType="ContentParagraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eum iure officia placeat quidem, repudiandae vitae voluptatibus. Ad aperiam architecto eius et, eum ex facilis, labore libero magnam minus modi nisi obcaecati possimus, quasi reprehenderit. Enim explicabo molestias numquam suscipit voluptate? Aliquid aperiam delectus laborum necessitatibus nihil nisi nobis perferendis quos ratione soluta! Blanditiis corporis dolorum illo, iure minima obcaecati qui rem saepe sed voluptatibus! At debitis tempore voluptate voluptatum!
    </ContentParagraph>
    <ContentParagraph mdxType="ContentParagraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, assumenda aut dignissimos doloribus ex ipsa iste itaque iure quibusdam quisquam quod repellendus sint suscipit tempore voluptas? Accusamus alias aliquam architecto corporis delectus eos explicabo fuga illum in ipsam itaque molestiae natus nobis nostrum pariatur, perferendis perspiciatis praesentium, quod repudiandae rerum suscipit ut voluptas. Atque cum hic itaque laudantium necessitatibus nobis possimus quibusdam? At consectetur nisi praesentium quis quod recusandae sint?
    </ContentParagraph>
  </>
    </Playground>
    <h3 {...{
      "id": "уменьшенный-вариант"
    }}>{`Уменьшенный вариант`}</h3>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`small`}</inlineCode>{` можно использовать уменьшенный вариант текста.`}</p>
    <Playground __position={3} __code={'<ContentParagraph small>\n  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus\n  aliquid animi delectus deserunt dicta dignissimos dolorum eos eum, incidunt\n  minima nam necessitatibus nihil nobis officiis perferendis, provident quae\n  quia quo ratione reiciendis repellendus rerum ullam, ut veniam voluptates\n  voluptatibus. Aspernatur commodi consequatur doloremque, facilis iste magnam\n  maiores nihil possimus recusandae repellat, sed ullam. Architecto\n  consectetur dolores nihil, odit perspiciatis quod saepe? Blanditiis culpa\n  dolorem earum nulla quibusdam reiciendis vel!\n</ContentParagraph>'} __scope={{
      props,
      DefaultLayout,
      ContentParagraph,
      Paragraph,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ContentParagraph small mdxType="ContentParagraph">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus aliquid animi delectus deserunt dicta dignissimos dolorum eos eum, incidunt minima nam necessitatibus nihil nobis officiis perferendis, provident quae quia quo ratione reiciendis repellendus rerum ullam, ut veniam voluptates voluptatibus. Aspernatur commodi consequatur doloremque, facilis iste magnam maiores nihil possimus recusandae repellat, sed ullam. Architecto consectetur dolores nihil, odit perspiciatis quod saepe? Blanditiis culpa dolorem earum nulla quibusdam reiciendis vel!
  </ContentParagraph>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Paragraph} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`p`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      